<template>
  <v-container fluid fill-height class="primary white--text text-xs-center" :class="{'afterLogin':afterLogin}">
    <v-layout align-center  v-if="!afterLogin">
      <v-flex sm12 align-content-center>
        <img :src="tank" style="max-width: 200px;" />
        <h1 class="mt-3 display-3 font-weight-black">{{ nameShort }}</h1>
        <h2>{{ nameLong }}</h2>
        <v-btn class="mt-4" color="white" id="loginButton" @click="loadLock">Accedi</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>

import { $EventBus } from '@/eventBus'

export default {
  data: () => ({
    afterLogin: false,
    tank: process.env.VUE_APP_TANK_PNG,
    nameShort: process.env.VUE_APP_NAME_SHORT,
    nameLong: process.env.VUE_APP_NAME_LONG,
    Lock: null,
    lockOptions: {
      language: 'it',
      allowSignUp: false,
      languageDictionary: {
        title: ''
      },
      theme: {
        logo: process.env.VUE_APP_LOGO_SVG_BIG_COLOR,
        primaryColor: process.env.VUE_APP_COLOR_SECONDARY,
        foregroundColor: process.env.VUE_APP_COLOR_SECONDARY
      }
    }
  }),
  methods: {
    loadLock () { // usiamo lock via cdn, il modulo diretto pesa troppo
      window.location.href = '/api/auth/login'
      /* let script = document.createElement('script')
      script.setAttribute('src', `https://cdn.auth0.com/js/lock/${process.env.VUE_APP_AUTH0_LOCK_VERSION}/lock.min.js`)
      script.async = true
      script.onload = () => {
        $EventBus.$emit('lockLoaded')
      }
      document.head.appendChild(script) */
    },
    getUserInfo () {
      this.$http.get('auth/current-user')
        .then(data => { this.userInfo = data; $EventBus.$emit('authenticated') })
        .catch(() => { this.userInfo = null })
    }
  },
  mounted () {
    this.getUserInfo()
    if (location.href.indexOf('access_token') !== -1) {
      this.afterLogin = true
      this.loadLock()
    }
    if (this.$route.query.unauthorized) {
      $EventBus.$emit('message', { type: 'error', text: 'Non hai i permessi per accedere' })
    }
    if (this.$route.query.expired) {
      $EventBus.$emit('message', { type: 'error', text: 'La tua sessione è scaduta, rieffettua l’accesso' })
    }
    $EventBus.$on('authenticated', () => {
      const profile = this.userInfo.data
      profile.loggedAt = new Date().getTime()
      profile.expires = 36000 * 1000 + new Date().getTime() // 12h
      this.$userLogin(profile)
      this.$router.push({ name: 'dashboard' })
    })
    /* $EventBus.$on('lockLoaded', () => {
      this.Lock = new Auth0Lock(process.env.VUE_APP_AUTH0_CLIENTID, process.env.VUE_APP_AUTH0_DOMAIN, {
        auth: {
          responseType: 'token',
          redirectUrl: process.env.VUE_AUTH0_CALLBACKURL,
          params: {
            scope: 'openid email user_metadata app_metadata picture'
          }
        }
      })
      this.Lock.show(this.lockOptions)
      this.Lock.on('authenticated', (response) => {
        this.Lock.getUserInfo(response.accessToken, (err, profile) => {
          if (err) {
            console.log(err)
            this.Lock.hide()
            let errorMessage = 'Ops, qualcosa è andato storto durante la login'
            if (err.statusCode === 429) {
              errorMessage = 'Troppi accessi, riprova tra qualche minuto'
            }
            $EventBus.$emit('message', { type: 'error', text: errorMessage })
            setTimeout(() => {
              location.href = '/login'
            }, process.env.VUE_APP_MESSAGES_TIMEOUT)
          } else {
            profile.accessToken = response.accessToken
            profile.loggedAt = new Date().getTime()
            profile.expires = 36000 * 1000 + new Date().getTime() // 12h
            profile.idToken = response.idToken
            this.$userLogin(profile)
            this.Lock.hide()
            this.$router.push({ name: 'dashboard' })
          }
        })
      })
    }) */
  }
}
</script>

<style>
  .auth0-lock.auth0-lock {
    z-index: 10!important; /* stai sotto ai messaggi */
  }
  .auth0-lock-close-button {
    display: none;  /* nascondi il chiudi */
  }
  .auth0-lock.auth0-lock .auth0-lock-overlay {
    background: #223A87!important;
  }
  .afterLogin .auth0-lock {
    visibility: hidden;
  }
</style>
